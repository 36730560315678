:root {
  --navbar-height: 50px;
  --font-family: "Verdana", Helvetica, Arial, Lucida, sans-serif;
  --proion-blue: #275d95;
  --proion-dark: #1f1f1f;
  --proion-dark-box-shadow: 4px 4px 20px 0px #0e0e12;
  --proion-btn-shadow: 0 0px 5px 0px #77baff;
  --proion-dark-tran: rgba(31, 31, 31, 0.95);
  --btn-border-radius: 3px;
  --btn-padding: 0.3em 1em;
  --txt-shadow: 0 0 3px #99aab2;
  --tab-border-radius: 5px;
  --proion-tab-borders: 4px Solid var(--proion-dark);
}
html {
  overflow: hidden;
}
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  text-shadow: var(--txt-shadow);
  background-color: var(--proion-dark);
}
input {
  cursor: pointer;
}
.mapboxgl-canvas {
  /* filter: brightness(1.2); */
}
.map-container {
  position: absolute;
  /* top: var(--navbar-height); */
  bottom: 0;
  width: 100%;
}

.mapboxgl-map {
  width: 100% !important;
  height: calc(100vh - var(--navbar-height)) !important;
}
.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder ul,
.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: var(--box-shadow) !important;
}
.mapboxgl-ctrl-geocoder--input:focus {
  outline: none !important;
}
.mapboxgl-ctrl-geocoder input[type="text"] {
  color: var(--harmonia-blue) !important;
  font-family: var(--font-family);
}
.navbar {
  font-weight: 600;
  height: var(--navbar-height);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--proion-dark);
}
.nav-logo {
  width: 120px;
  margin-left: 20px;
}
.navbar-about-btn {
  margin-right: 20px;
  border: none;
  color: #fff;
  background: none;
  font-size: 15px;
  font-weight: bold;
  font-family: sans-serif;
  border-radius: var(--btn-border-radius);
  padding: var(--btn-padding);
  cursor: pointer;
  text-shadow: var(--txt-shadow);
}

.navbar-about-btn:hover {
  color: #fff;
  background: var(--proion-blue);
}

.main-form {
  position: absolute;
  top: calc(10%);
  right: calc(50% - 500px);
  width: 1000px;
  /* height: 500px; */
  background: var(--proion-dark-tran);
  border-radius: var(--tab-border-radius);
  z-index: 1000;
  color: #fff;
  font-family: var(--font-family);
  box-shadow: var(--proion-dark-box-shadow);
}

.glass-background {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: -50px;
  z-index: 2;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(1px);
}

.main-form-content {
  margin: 10px 40px 40px 50px;
  overflow-y: hidden;
  height: 425px;
}
.main-form-content-content {
  overflow-y: hidden;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 25% 33% 35%;
  grid-template-rows: 190px;
  column-gap: 20px;
  row-gap: 10px;
}

.main-form-header {
  font-size: 20px;
  font-family: var(--font-family);
  padding: 25px 25px 15px 25px;
  border-bottom: 2px solid var(--proion-blue);
}

.form-headers {
  background: #275d95bf;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
  height: 20px;
}
.form-footer {
  position: absolute;
  bottom: 5px;
  height: 50px;
  text-align: right;
  width: 100%;
  z-index: 2;
  border-top: 2px solid var(--proion-blue);
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.form-groups {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  padding-left: 15px;
  padding-bottom: 5px;
  font-size: 14px;
  margin-top: 7px;
}

#form-resvalues-group {
  height: 140px;
  overflow-y: scroll;
}

#observations-group {
  height: 161px;
  overflow-y: scroll;
}

span.resVal-dataset-name {
  font-size: 13px;
  margin-right: 10px;
  margin-left: 10px;
}

.form-plus-icon {
  font-weight: bold;
  cursor: pointer;
  margin-right: 5px;
  font-size: 19px;
}

.form-labels {
  padding-top: 3px;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}
.form-labels > input {
  cursor: pointer;
}
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin-right: 8px;
  appearance: none;
  font: inherit;
  color: currentColor;
  width: 0.9em;
  height: 0.9em;
  border: 0em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
}

input[type="radio"]::before {
  content: "";
  width: 0.3em;
  height: 0.3em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
input[type="radio"]:checked {
  width: 0.8em;
  height: 0.8em;
  outline: max(1px, 0.1em) solid var(--proion-blue);
  outline-offset: max(1px, 0.15em);
  background: #83aedd;
  /* box-shadow: inset 1em 1em var(--proion-blue); */
}

.form-date-input {
  margin-left: 15px;
}

.datasets-obs-period {
  font-size: 10px;
  margin-left: 20px;
}

#form-submit-btn {
  margin-right: 50px;
  padding: 7px 12px;
  border-radius: var(--btn-border-radius);
  background: var(--proion-blue);
  border: none;
  color: #fff;
  font-family: var(--font-family);
  cursor: pointer;
}

#form-submit-btn:hover {
  box-shadow: var(--proion-btn-shadow);
}

#aggregation-value {
  width: 30px !important;
  margin-left: 5px;
}

#aggregation-duration {
  width: 80px !important;
  margin-left: 5px;
  height: 23px !important;
}

#aggregation-function {
  width: 60px !important;
  margin-left: 5px;
  height: 23px !important;
}

option {
  color: #fff;
  text-align: center;
  background-color: var(--proion-dark);
  font-family: var(--font-family);
}

#resvalues-observation {
  width: 115px !important;
  height: 23px !important;
}
#resvalues-condition {
  width: 77px !important;
  height: 23px !important;
}
#resvalues-value {
  width: 35px !important;
  height: 17px !important;
}

.bbox-values,
.distance-center-values {
  width: 90px !important;
}

button#draw-form-bbox,
button#draw-form-distance {
  margin-top: 10px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
  background: var(--proion-blue);
  color: #fff;
  font-family: var(--font-family);
  text-shadow: var(--txt-shadow);
  border: none;
  border-radius: var(--btn-border-radius);
  padding: 7px;
  font-size: 12px;
  cursor: pointer;
}

button#clear-form-bbox,
button#clear-form-distance {
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  background: #eb5757;
  color: #fff;
  border: none;
  border-radius: var(--btn-border-radius);
  padding: 7px 10px;
  font-size: 12px;
  cursor: pointer;
}

button#clear-form-bbox:hover,
button#clear-form-distance:hover {
  box-shadow: var(--proion-btn-red-shadow);
}
button#draw-form-bbox:hover,
button#draw-form-distance:hover {
  box-shadow: var(--proion-btn-shadow);
}

.distance-rad-values {
  width: 55px !important;
}

.BBOX-small-tab,
.Distance-small-tab {
  position: absolute;
  top: 137px;
  right: 70px;
  z-index: 100;
  background: var(--proion-dark-tran);
  box-shadow: var(--proion-dark-box-shadow);
  color: #fff;
  padding: 15px;
  border-radius: var(--tab-border-radius);
}

.cursor-box {
  position: absolute;
  z-index: 100;
  height: auto;
  background: var(--proion-dark-tran);
  box-shadow: var(--proion-dark-box-shadow);
  color: #fff;
  padding: 15px;
  border-radius: var(--tab-border-radius);
  margin-top: 40px;
  margin-left: 30px;
  font-size: 12px;
}

/* ---------chart-------- */
.chart-tab {
  position: absolute;
  bottom: 50px;
  height: 300px;
  background: var(--proion-dark-tran);
  width: 95%;
  margin-left: 2.5%;
  border-radius: var(--tab-border-radius);
  display: flex;
  flex-direction: row;
  color: #fff;
  z-index: 2;
  box-shadow: var(--proion-dark-box-shadow);
}

.chart-container {
  width: 95%;
  margin-left: -2%;
  height: 90%;
  margin-top: 20px;
  cursor: pointer;
  /* color: #0000ff;
  color: #ff0000;
  color: yellow;
  color: #ffac00;
  color: #ccff00;
  color: #00ff02;
  color: #00ffcc;
  color: #00a7ff;
  color: #4141ff;*/
  /* color: #ae00ff;  */
}

.chart-site {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
}

.chart-container.data {
  width: 95%;
  margin-left: 4%;
  height: 90%;
  margin-top: 20px;
  cursor: pointer;
}

#close-chart-btn,
.close-modal,
#close-filter-search-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  font-size: 28px;
  width: 30px;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: none;
  border: none;
  font-weight: bold;
  color: #275d95;
  cursor: pointer;
}

#search-btn-content:hover {
  content: "Data Search";
}

#close-chart-btn:hover {
  font-size: 30px;
}
.chart-properties {
  display: flex;
  font-size: 12px;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 20px;
  width: 190px;
}

.form-date-input,
#aggregation-value,
#aggregation-duration,
#aggregation-function,
#resvalues-observation,
#resvalues-condition,
#resvalues-value,
.bbox-values,
.distance-center-values,
.distance-rad-values,
.chart-date-input,
.chart-dropdown {
  width: 100px;
  height: 18px;
  border-radius: var(--btn-border-radius);
  border: 1px solid var(--proion-blue);
  padding: 2px 5px;
  background: transparent;
  text-align: center;
  outline: none;
  font-family: var(--font-family);
  font-size: 11px;
  cursor: pointer;
  color: #fff;
  color-scheme: dark;
  text-shadow: var(--txt-shadow);
}

.form-date-input:focus,
#aggregation-value:focus,
#aggregation-duration:focus,
#aggregation-function:focus,
#resvalues-observation:focus,
#resvalues-condition:focus,
#resvalues-value:focus,
.bbox-values:focus,
.distance-center-values:focus,
.distance-rad-values:focus,
.chart-date-input:focus,
.chart-dropdown:focus {
  box-shadow: var(--proion-dark-box-shadow);
}

.chart-date-input-label {
  margin-top: 15px;
  margin-bottom: 2px;
}

.chart-dropdown {
  width: 112px;
  height: 23px;
}
.chart-dropdown option {
  background: var(--proion-dark);
}
/*----------------- Map ToolBox ---------------*/

.map-toolbar {
  position: absolute;
  top: calc(var(--navbar-height) + 10px) !important;
  margin-left: 15px;
}

.map-toolbar-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.map-toolbar-btns {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--proion-blue);
  color: #fff;
  font-size: 18px;
  margin-bottom: 7px;
  border: none;
  cursor: pointer;
}

.map-toolbar-btns:hover {
  box-shadow: 0 0px 5px 0px #77baff;
}

/*--------------- Tooltips -------------*/

span.left-tooltip,
span.rightClick-tooltip {
  visibility: hidden;
  display: block;
  font-family: var(--font-family);
  background-color: var(--proion-blue);
  color: #fff;
  text-shadow: var(--txt-shadow);
  font-size: 14px;
  text-align: center;
  border-radius: 6px;
  padding: 9px 0px;
  position: absolute;
  z-index: 1;
  opacity: 1;
  transition: 0.4s;
  left: 54px !important;
  height: 23px;
  min-width: 90px;
  margin-top: -29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

span.rightClick-tooltip {
  visibility: visible !important;
  opacity: 1 !important;
  width: 250px !important;
  font-size: 13px;
}

span.left-tooltip::after,
span.rightClick-tooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent var(--proion-blue) transparent transparent;
}

button.map-toolbar-btns:hover span.left-tooltip,
button.map-toolbar-btns:hover .basemaps-box,
button.map-toolbar-btns:hover .layers-box,
button.map-toolbar-btns:hover .point-cloud-box {
  visibility: visible;
  opacity: 1;
}

/*basemaps*/

.basemaps-box,
.layers-box,
.point-cloud-box {
  visibility: hidden;
  display: block;
  z-index: 100;
  width: 240px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: 0.6s;
  left: 60px !important;
  margin-top: -35px;
  background: var(--proion-dark-tran);
  border: 4px solid var(--proion-dark);
  border-radius: var(--btn-border-radius);
  align-content: center;
  padding: 15px;
  display: grid;
  column-gap: 7px;
  row-gap: 7px;
  grid-template-columns: auto auto;
  padding: 10px;
  box-shadow: var(--proion-dark-box-shadow);
}

.layers-box {
  visibility: visible !important;
  opacity: 1 !important;
  position: absolute !important;
  color: #fff !important;
  margin-top: -50px !important;
  z-index: 3;
}

/* Point Cloud Tool */
svg.svg-inline--fa.fa-cubes {
  margin-left: 3px;
}

.point-cloud-box {
  /* visibility: visible;
  opacity: 1; */
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.point-clouds-disclaimer {
  font-size: 10px;
  text-align: left;
  margin-top: 10px;
}

.legend-title {
  width: 100%;
  font-weight: bold;
  border-bottom: 1px solid var(--proion-blue);
  padding: 7px;
  font-family: var(--font-family);
  text-shadow: var(--txt-shadow);
  margin-bottom: 5px;
}

.point-cloud-labels {
  font-size: 12px;
  text-shadow: var(--txt-shadow);
  font-family: var(--font-family);
  line-height: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 10px;
}

.point-cloud-loading-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.point-cloud-loading-percentage {
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 40%);
  font-size: 16px;
  font-family: var(--font-family);
  text-shadow: var(--txt-shadow);
  color: #fff;
  text-shadow: var(--text-sdw);
  font-weight: bold;
}

/* ------------------------------------- */

.basemaps-box::after,
.layers-box::after,
.point-cloud-box::after {
  content: "";
  position: absolute;
  top: 5px;
  right: 101%;
  border-width: 16px;
  border-style: solid;
  border-color: transparent var(--proion-dark-tran) transparent transparent;
}

.basemap-img {
  width: 100px;
  /* padding: 5px; */
  border-radius: var(--btn-border-radius);
  border: 2px Solid var(--proion-blue);
}

.basemap-img:hover {
  box-shadow: 0 0px 5px 0px #77baff;
}

.basemaps-labels {
  font-size: 11px;
  text-shadow: var(--txt-shadow);
  font-family: var(--font-family);
  line-height: 18px;
  cursor: pointer;
}

.mapboxgl-marker.mapboxgl-marker-anchor-center {
  cursor: pointer;
}

.mapboxgl-popup-content {
  border-radius: var(--btn-border-radius) !important;
  background: var(--proion-dark-tran) !important;
  color: #fff !important;
  border: var(--proion-tab-borders);
  width: 340px !important;
  margin-left: -76px;
  font-family: var(--font-family) !important;
  padding: 20px 19px 15px !important;
  box-shadow: var(--proion-dark-box-shadow) !important;
}

.popup-inner-content {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.popup-tabs-btns {
  border: none;
  background: var(--proion-blue);
  color: #fff;
  padding: 6px;
  margin-left: 2px;
  border-radius: 5px 5px 0px 0px;
  text-shadow: var(--txt-shadow);
  font-family: var(--font-family);
  font-size: 12px;
  cursor: pointer;
  outline: none;
}

.dataset-tab {
  width: 80%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  /* box-shadow: inset 0px 0px 9px 0px var(--proion-blue); */
}

td > a {
  color: #fff;
}
tr.popup-datasets-tr {
  vertical-align: top;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  border-top-color: var(--proion-dark) !important;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: var(--proion-dark) !important;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: var(--proion-dark) !important;
}
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: var(--proion-dark) !important;
}

table.popup-cont {
  width: 100%;
}
table.popup-cont tbody {
  width: 85%;
  margin-left: 0px;
  border-collapse: collapse;
}
table.popup-cont th {
  background: none;
  width: 130px;
  font-size: 12px;
  text-align: right;
  border: none;
  vertical-align: top;
}
table.popup-cont td {
  background: none;
  /* width: 145px; */
  font-size: 11px;
  text-align: left;
  border: none;
  padding: 2px;
}

.stations-chart-btn {
  background: none;
  color: #fff;
  border: none;
  text-shadow: var(--txt-shadow);
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 16px;
  cursor: pointer;
  padding: var(--btn-padding);
  border-radius: var(--btn-border-radius);
  border: 0.5px solid var(--proion-blue);
  width: 60px;
}

.stations-chart-btn:hover {
  background: var(--proion-blue);
}
.mapboxgl-popup-close-button {
  background-color: transparent;
  border: 0;
  border-radius: 0 3px 0 0;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  text-shadow: var(--txt-shadow);
  font-size: 20px;
}

.layers-box {
  /* visibility: visible;
  opacity: 1; */
  width: 310px;
  font-size: 12px;
  text-shadow: var(--txt-shadow);
  font-family: var(--font-family);
  line-height: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.data-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-family);
  text-shadow: var(--txt-shadow);
  font-size: 12px;
  width: 100%;
  row-gap: 10px;
  padding-bottom: 10px;
}
.datatool-station-header {
  border-bottom: 2px solid var(--proion-blue);
  border-radius: 0px !important;
  font-size: 15px;
  width: 100%;
  padding: 7px;
  display: flex;
  justify-content: center;
}
.datatool-dataset-header {
  background: var(--proion-blue);
  border-radius: var(--btn-border-radius);
  width: 180px;
  padding: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 10px;
}

.datatool-chart-btn {
  position: absolute;
  right: 60px;
  border-radius: 25%;
  background: var(--proion-blue);
  color: #fff;
  border: none;
  height: 25px;
  cursor: pointer;
}
.datatool-chart-btn:hover {
  box-shadow: var(--proion-btn-shadow);
}

.datatool-dataset-context {
  margin-top: 5px;
  line-height: 12px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 180px;
}

.layer-legend {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
}
.layer-symbol.ramp {
  width: 170px;
}
.layer-symbol.tile {
  width: 30px;
  border-radius: var(--btn-border-radius);
}
.layer-label {
  margin-left: 10px;
}
input.layers-cb {
  margin-right: 10px;
  cursor: pointer;
}

/* Loading */

#loading {
  position: absolute;
  width: 100%;
  height: 100% !important;
  z-index: 20002;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  flex-direction: column;
  margin-top: calc(var(--navbar-height) * (-1));
  top: var(--navbar-height);
}

.loading-bar-placeholder {
  width: 50%;
  background: var(--proion-dark-tran);
  height: 11px;
  border-radius: 12px;
  box-shadow: inset 0px 0px 5px 0px #8b8a8a;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
}

.loading-bar {
  width: 0%;
  height: 54%;
  background: var(--proion-blue);
  position: relative;
  border-radius: 12px;
}

.loading-logo-image {
  width: 207px;
  margin-top: -180px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 12px 0px #ffffff59;
  background: #ffffff59;
  border-radius: 5px;
}

.secondaryLoading-bar {
  width: 0px;
  position: absolute;
  height: 3px;
  top: var(--navbar-height);
  z-index: 5000;
  background: var(--proion-blue);
  box-shadow: 0px 1px 4px var(--proion-blue);
}

.secondaryLoading-bar-line {
  width: 150px;
  position: absolute;
  height: 3px;
  top: var(--navbar-height);
  z-index: 5000;
  background: var(--proion-blue);
  box-shadow: 0px 1px 4px var(--proion-blue);
  animation-name: "progress";
  animation-duration: 0.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: "progress";
  -webkit-animation-duration: 0.6s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@keyframes progress {
  0% {
    -webkit-transform: translateX(150px);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateX(100vw);
    visibility: hidden;
  }
}

@-webkit-keyframes progress {
  0% {
    -webkit-transform: translateX(150px);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateX(100vw);
    visibility: hidden;
  }
}

/* ----------About Modal ------------ */

.about-modal {
  position: fixed; /* Stay in place */
  z-index: 2000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;

  /* opacity:0; */
  /* transition: 2s; */
}

.modal {
  width: 50%;
  position: absolute;
  top: 7%;
  right: 25%;
  border-style: solid;
  border-radius: var(--btn-border-radius);
  border-color: var(--proion-dark);
  /* box-shadow: var(--proion-dark-box-shadow); */
  background-color: var(--proion-dark-tran);
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.8s;
  animation-name: slideIn;
  animation-duration: 0.8s;
}
.modal-header {
  width: 100%;
  margin-top: -20px;
  border-bottom: 1px solid var(--proion-blue);
}
.modal-title {
  color: #fff;
  text-shadow: var(--text-sdw);
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 1.25rem;
  padding-top: 15px;
  margin-left: 20px;
}

.modal-subtitle {
  color: #fff;
  text-shadow: var(--text-sdw);
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 15px;
  padding-top: 0px;
  margin-left: 20px;
}

.modal-body {
  min-height: 100px;
  padding: 1rem;
  font-family: var(--font-family);
  font-size: 14px;
  color: #fff;
  text-shadow: var(--text-sdw);
  overflow-y: hidden !important;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-bottom: 70px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  line-height: 19px;
}
.modal-footer {
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: 0px;
  border-top: 1px solid var(--proion-blue);
}

button.continue-btn {
  position: absolute;
  right: 30px;
  top: 15px;
  color: #fff;
  padding: 5px 10px;
  border: 1px solid var(--proion-blue);
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-family: var(--font-family);
  background-color: var(--proion-blue);
  border-radius: var(--btn-border-radius);
  cursor: pointer;
}
button.continue-btn:hover {
  box-shadow: var(--proion-dark-box-shadow);
  background: var(--proion-blue);
  text-decoration: none;
}

@-webkit-keyframes slideIn {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 7%;
    opacity: 1;
  }
}
@keyframes slideIn {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 7%;
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: var(--proion-blue);
  border-radius: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  border-radius: 7px;
  cursor: pointer;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoomToHome .mapboxgl-ctrl-icon {
  /* background-image: url(/src/img/control_globe.png); */
}

.funding-window {
  position: absolute;
  bottom: -3px;
  right: 0px;
}

.funding-img {
  width: 220px;
  opacity: 0.8;
  border-radius: 3px 0 0 0;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none;
}

.media-tab {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
}

/* .switch-media{
  border: 1px solid var(--proion-blue);
  border-radius: var(--btn-border-radius);  
} */
.media-tab-btns {
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.media-tab-btns:hover {
  color: var(--proion-blue);
  font-size: 1.05rem;
}

video {
  border-radius: var(--btn-border-radius);
  width: 100%;
}
.media-tab-title {
  margin-bottom: 11px;
  margin-left: -130px;
  font-size: 13px;
}

.media-props {
  padding-right: 5px;
}

.popup-inner-content.reflectors {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.reflectors-video-content {
  width: 310px;
  text-align: center;
}

.point-cloud-video-box {
  position: absolute;
  left: 300px;
  top: 45px;
  width: 275px;
  padding: 15px;
  background: var(--proion-dark-tran);
  border-radius: var(--btn-border-radius);
  box-shadow: var(--proion-dark-box-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
}

.point-cloud-video-box::after {
  content: "";
  position: absolute;
  top: calc(50% - 16px);
  right: 100%;
  border-width: 16px;
  border-style: solid;
  border-color: transparent var(--proion-dark-tran) transparent transparent;
}

/* Loader */
@keyframes ldio-pwpcxr5ke3q {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-pwpcxr5ke3q div {
  position: absolute;
  width: 69.02px;
  height: 69.02px;
  border: 2px solid #275d95;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-pwpcxr5ke3q div {
  animation: ldio-pwpcxr5ke3q 1s linear infinite;
  top: 101.49999999999999px;
  left: 101.49999999999999px;
}
.loadingio-spinner-rolling-pqx4z9i4nsh {
  width: 203px;
  height: 203px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-pwpcxr5ke3q {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-pwpcxr5ke3q div {
  box-sizing: content-box;
}

/* Login Page */
#loginPage {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 20002;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page-content {
  width: 240px;
  height: 215px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 80px 50px 80px;
  background: var(--proion-dark-tran);
  border-radius: var(--tab-border-radius);
  border: 4px solid var(--proion-dark);
  margin-top: -60px;
  box-shadow: var(--proion-dark-box-shadow);
}
.login-logo-image {
  width: 155px;
  margin-bottom: 20px;
  border-radius: 5px;
}
#login-glass-background {
  top: 0px;
  height: 120%;
  backdrop-filter: blur(2px);
}

.inputs-container {
  width: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login-inputs {
  width: 100%;
  height: 30px;
  border: none;
  border-bottom: 1px solid var(--proion-blue);
  padding: 2px 5px;
  background: transparent;
  text-align: left;
  outline: none;
  font-family: var(--font-family);
  font-size: 13px;
  cursor: pointer;
  color: #fff;
  color-scheme: dark;
  text-shadow: var(--txt-shadow);
  margin-bottom: 15px;
}

.login-inputs:focus {
  color: #fff;
  border-bottom: 3px solid var(--proion-blue);
  cursor: text;
}

.login-inputs-icons {
  color: rgb(39 93 149 / 50%);
  border-bottom: 1px solid var(--proion-blue);
  padding-bottom: 8px;
  margin-bottom: 8px;
  height: 19px;
  padding-right: 10px;
}

.login-inputs-icons-focused {
  color: var(--proion-blue);
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 3px solid var(--proion-blue);
  height: 19px;
  padding-right: 10px;
}

#log-in-btn {
  background: var(--proion-blue);
  border: none;
  border-radius: var(--btn-border-radius);
  color: #fff;
  font-family: var(--font-family);
  text-shadow: var(--txt-shadow);
  padding: 7px 19px;
  font-size: 14px;
  margin-top: 10px;
}
#log-in-btn:hover {
  box-shadow: var(--proion-btn-shadow);
  cursor: pointer;
}

.wrong-login {
  color: #bc4545;
  text-shadow: 0px 0px 5px #a93737;
  font-size: 14px;
}

/* Alert Tab */
.alert-tab {
  width: 300px;
  height: 250px;
  position: absolute;
  top: 50px;
  left: 50%;
  margin-left: -150px;
  background: var(--proion-dark-tran);
  z-index: 20003;
  border-radius: var(--tab-border-radius);
  border: var(--proion-tab-borders);
}

.checked-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--proion-blue);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: var(--proion-blue);
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px var(--proion-blue);
  animation: fill 0.5s ease-in-out 0.5s forwards,
    scale 0.4s ease-in-out 1s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) 0.9s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px va(--proion-blue);
  }
}
